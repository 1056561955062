import React, { useState, useEffect, useRef } from "react";

export default function InfographicPage() {
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({
    width: "100%",
    height: "100vh",
  });
  const [infographicData, setInfographicData] = useState({
    src: "",
    title: "",
  });

  useEffect(() => {
    fetch("/config/infographics.config.json")
      .then((response) => response.json())
      .then((data) => {
        if (data.infographics && data.infographics.length > 0) {
          setInfographicData({
            src: data.infographics[0],
            title: data.infographicTitle ? data.infographicTitle[0] : "Infographic",
          });
        }
      })
      .catch((error) => console.error("Error loading config:", error));

    // Function to update container size
    const updateContainerSize = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        const height = window.innerHeight;
        setContainerSize({ width: `${width}px`, height: `${height}px` });
      }
    };

    updateContainerSize();

    window.addEventListener("resize", updateContainerSize);

    return () => window.removeEventListener("resize", updateContainerSize);
  }, []);

  return (
    <div ref={containerRef} className="w-full h-screen overflow-hidden bg-black">
     
      {infographicData.src ? (
        <iframe
          src={infographicData.src}
          title={infographicData.title}
          width={containerSize.width}
          height={containerSize.height}
          style={{ border: "none", overflow: "hidden" }}
        />
      ) : (
        <p className="text-white text-center mt-20">Loading infographic...</p>
      )}
    </div>
  );
}
